var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('name')
                ? _vm.errors.first('name')
                : '',"variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : '',"label":'Nombre'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"name","placeholder":"Ingrese el nombre","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : ''},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('date_from')
                ? _vm.errors.first('date_from')
                : '',"variant":_vm.showValidation && _vm.errors.first('date_from') ? 'danger' : '',"label":'Fecha de inicio'}},[_c('t-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"initial-view":"month","lang":"es","closeOnSelect":true,"data-vv-validate-on":"input","name":"date_from","id":"date_from","placeholder":"Seleccione la fecha de inicio","disabled":_vm.loading,"variant":_vm.showValidation && _vm.errors.first('date_from') ? 'danger' : ''},model:{value:(_vm.form.date_from),callback:function ($$v) {_vm.$set(_vm.form, "date_from", $$v)},expression:"form.date_from"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('date_to')
                ? _vm.errors.first('date_to')
                : '',"variant":_vm.showValidation && _vm.errors.first('date_to') ? 'danger' : '',"label":'Fecha de fin'}},[_c('t-datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"initial-view":"month","lang":"es","closeOnSelect":true,"data-vv-validate-on":"input","name":"date_to","id":"date_to","placeholder":"Seleccione la fecha de fin","disabled":_vm.loading,"variant":_vm.showValidation && _vm.errors.first('date_to') ? 'danger' : ''},model:{value:(_vm.form.date_to),callback:function ($$v) {_vm.$set(_vm.form, "date_to", $$v)},expression:"form.date_to"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_tournament_region')
                ? _vm.errors.first('id_tournament_region')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_tournament_region')
                ? 'danger'
                : '',"label":'Región del torneo'}},[_c('t-select',{attrs:{"placeholder":"Selecciona una región","name":"id_tournament_region","options":_vm.regions,"textAttribute":"name","valueAttribute":"id","variant":_vm.showValidation && _vm.errors.first('id_tournament_region')
                  ? 'danger'
                  : ''},model:{value:(_vm.form.id_tournament_region),callback:function ($$v) {_vm.$set(_vm.form, "id_tournament_region", $$v)},expression:"form.id_tournament_region"}})],1),_c('t-input-group',{staticClass:"w-full md:mr-0 md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_logo')
                ? _vm.errors.first('url_logo')
                : '',"variant":_vm.showValidation && _vm.errors.first('url_logo') ? 'danger' : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Logo del torneo")]),_c('div',{staticClass:"mx-auto w-full md:w-1/2 mx-auto"},[_c('cropper-upload',{attrs:{"file":_vm.form.url_logo,"externalUrl":typeof _vm.form.url_logo === 'string' ? _vm.form.url_logo : null,"title":"Logo del torneo","aspectRatio":1,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_logo", $event)}}})],1)])],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1 relative"},[_c('t-input-group',{staticClass:"w-full md:mr-0 md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_header')
                ? _vm.errors.first('url_header')
                : '',"variant":_vm.showValidation && _vm.errors.first('url_header') ? 'danger' : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Banner del torneo")]),_c('div',{staticClass:"mx-auto w-full md:w-1/2 mx-auto"},[_c('cropper-upload',{attrs:{"file":_vm.form.url_header,"externalUrl":typeof _vm.form.url_header === 'string' ? _vm.form.url_header : null,"title":"Banner del torneo","aspectRatio":16 / 9,"height":"250px","backgroundColor":"bg-white"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_header", $event)}}})],1)]),_c('div',{staticClass:"flex justify-start md:ml-2 md:mr-2 my-4"},[_c('p',[_vm._v("Habilitado")]),_c('t-toggle',{staticClass:"mx-3",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.toggleVisibility(!_vm.visible)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}),_c('p',[_vm._v("Sí")])],1),(_vm.deletable)?_c('div',{staticClass:"absolute bottom-0 w-full flex justify-center"},[_c('t-button',{attrs:{"type":"button","variant":"error"},on:{"click":_vm.deleteElement}},[_vm._v("Eliminar torneo")])],1):_vm._e()],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }