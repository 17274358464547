<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombre'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.name"
                name="name"
                placeholder="Ingrese el nombre"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('date_from')
                  ? errors.first('date_from')
                  : ''
              "
              :variant="
                showValidation && errors.first('date_from') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Fecha de inicio'"
            >
              <t-datepicker
                v-model="form.date_from"
                initial-view="month"
                lang="es"
                :closeOnSelect="true"
                v-validate="'required'"
                data-vv-validate-on="input"
                name="date_from"
                id="date_from"
                placeholder="Seleccione la fecha de inicio"
                :disabled="loading"
                :variant="
                  showValidation && errors.first('date_from') ? 'danger' : ''
                "
              >
              </t-datepicker>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('date_to')
                  ? errors.first('date_to')
                  : ''
              "
              :variant="
                showValidation && errors.first('date_to') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Fecha de fin'"
            >
              <t-datepicker
                v-model="form.date_to"
                initial-view="month"
                lang="es"
                :closeOnSelect="true"
                v-validate="'required'"
                data-vv-validate-on="input"
                name="date_to"
                id="date_to"
                placeholder="Seleccione la fecha de fin"
                :disabled="loading"
                :variant="
                  showValidation && errors.first('date_to') ? 'danger' : ''
                "
              >
              </t-datepicker>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('id_tournament_region')
                  ? errors.first('id_tournament_region')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_tournament_region')
                  ? 'danger'
                  : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Región del torneo'"
            >
              <t-select
                v-model="form.id_tournament_region"
                placeholder="Selecciona una región"
                name="id_tournament_region"
                :options="regions"
                textAttribute="name"
                valueAttribute="id"
                :variant="
                  showValidation && errors.first('id_tournament_region')
                    ? 'danger'
                    : ''
                "
              ></t-select>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('url_logo')
                  ? errors.first('url_logo')
                  : ''
              "
              :variant="
                showValidation && errors.first('url_logo') ? 'danger' : ''
              "
              class="w-full md:mr-0 md:ml-2 md:mr-2 my-4"
            >
              <label class="block text-left font-bold">Logo del torneo</label>
              <div class="mx-auto w-full md:w-1/2 mx-auto">
                <cropper-upload
                  :file.sync="form.url_logo"
                  :externalUrl="
                    typeof form.url_logo === 'string' ? form.url_logo : null
                  "
                  title="Logo del torneo"
                  :aspectRatio="1"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </div>
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1 relative">
            <t-input-group
              :feedback="
                showValidation && errors.first('url_header')
                  ? errors.first('url_header')
                  : ''
              "
              :variant="
                showValidation && errors.first('url_header') ? 'danger' : ''
              "
              class="w-full md:mr-0 md:ml-2 md:mr-2 my-4"
            >
              <label class="block text-left font-bold">Banner del torneo</label>
              <div class="mx-auto w-full md:w-1/2 mx-auto">
                <cropper-upload
                  :file.sync="form.url_header"
                  :externalUrl="
                    typeof form.url_header === 'string' ? form.url_header : null
                  "
                  title="Banner del torneo"
                  :aspectRatio="16 / 9"
                  height="250px"
                  backgroundColor="bg-white"
                />
              </div>
            </t-input-group>

            <div class="flex justify-start md:ml-2 md:mr-2 my-4">
              <p>Habilitado</p>
              <t-toggle
                class="mx-3"
                v-model="visible"
                @click="toggleVisibility(!visible)"
                :disabled="loading"
              />
              <p>Sí</p>
            </div>

            <div
              class="absolute bottom-0 w-full flex justify-center"
              v-if="deletable"
            >
              <t-button type="button" variant="error" @click="deleteElement"
                >Eliminar torneo</t-button
              >
            </div>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        name: null,
        id_tournament_region: null,
        date_from: null,
        date_to: null,
        url_logo: null,
        url_header: null
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    regions() {
      return this.$store.getters["global/getItems"](
        "tournamentRegion",
        "unpaginated_elements"
      )
    },
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          if (!this.form.url_logo) {
            return this.$snotify.error("El logo es obligatorio", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
          }
          if (!this.form.url_header) {
            return this.$snotify.error("El banner es obligatorio", "Error", {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom"
            })
          }
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente el torneo.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/tournament",
                module: "tournament"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/torneos")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/category/all", "category", true)
    this.getItems("/tournament_region/all", "tournamentRegion", true)
  }
}
</script>
