<template>
  <div>
    <Form
      title="Crear torneo"
      ref="tournamentForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.tournamentForm.form
      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: {
            id_tournament_region: form.id_tournament_region,
            name: form.name,
            date_from: form.date_from,
            date_to: form.date_to,
            url_logo: form.url_logo,
            url_header: form.url_header,
            status: 1,
            active: !this.visible ? 0 : 1
          },
          route: "/tournament/save",
          module: "tournament"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/torneos")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
